import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, FormControl, FormLabel, Input, VStack, useToast } from '@chakra-ui/react';
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom';
import { login } from '../auth';

const LoginPage = () => {
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();
    const toast = useToast(); // Initialize useToast

    const onSubmit = async (data) => {
        try {
            // Create a FormData object
            const formData = new FormData();
            formData.append('username', data.username);
            formData.append('password', data.password);

            // Send form data via fetch
            const response = await login(data.username, data.password);

            if (response.access_token) {
                toast({
                    title: 'Login successful.',
                    description: 'You have been logged in successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                // Redirect on successful login
                navigate('/dashboard');
            } else {
                // Handle error response
                console.error('Login failed', await response.text());
                toast({
                    title: 'Login failed.',
                    description: response.text(),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                // Handle error (e.g., display a message to the user)
            }
        } catch (error) {
            console.error('Login failed', error);
            toast({
                title: 'Login failed.',
                description: 'Invalid username or password.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box p={5}>
            <Card w="100%" maxW="md" mx="auto" >
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>Username</FormLabel>
                                <Input {...register('username', { required: 'Username is required' })} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Password</FormLabel>
                                <Input
                                    type="password"
                                    {...register('password', { required: 'Password is required' })}
                                />
                            </FormControl>
                            <Button type="submit" colorScheme="teal">Login</Button>
                        </VStack>
                    </form>
                </CardBody>
            </Card>


        </Box>
    );
};

export default LoginPage;
