import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, SimpleGrid, Card, CardHeader, CardBody, Center } from '@chakra-ui/react';
import { Bar } from 'react-chartjs-2';
import Navbar from '../components/Navbar';
import api from '../api';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { set } from 'react-hook-form';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [todayCount, setTodayCount] = useState(0);
  const [monthToDateCount, setMonthToDateCount] = useState(0);
  const [yearToDateCount, setYearToDateCount] = useState(0);
  const [countsByDay, setCountsByDay] = useState([]);

  useEffect(() => {
    const fetchSummary = async () => {
      const responseToday = await api.get('/summary/count_today');
      setTodayCount(responseToday.data.today);
      setMonthToDateCount(responseToday.data.month);
      setYearToDateCount(responseToday.data.year);

      const responseByDay = await api.get('/summary/count_by_day');
      setCountsByDay(responseByDay.data);
    };
    fetchSummary();
  }, []);

  const data = {
    labels: countsByDay.map(item => `${item._id.year}-${item._id.month}-${item._id.day}`),
    datasets: [
      {
        label: 'Orders',
        data: countsByDay.map(item => item.count),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box>
      <Navbar />
      <Box p={5}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
          <Card>
            <CardHeader>Today</CardHeader>
            <CardBody>
              <Center><Heading size="2xl">{todayCount}</Heading></Center>
              <Center><Text mt={5}>Insurance Orders</Text></Center>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Month to Date</CardHeader>
            <CardBody>
              <Center><Heading size="2xl">{monthToDateCount}</Heading></Center>
              <Center><Text mt={5}>Insurance Orders</Text></Center>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Year to Date</CardHeader>
            <CardBody>
              <Center><Heading size="2xl">{yearToDateCount}</Heading></Center>
              <Center><Text mt={5}>Insurance Orders</Text></Center>
            </CardBody>
          </Card>

        </SimpleGrid>
        <Card mt={5}>
          <CardBody> {/* Remove default padding */}
            <Box w="100%" h="400px"> {/* Ensure chart container takes full width and set height */}
              <Bar data={data} options={{ responsive: true, maintainAspectRatio: false }} />
            </Box>
          </CardBody>
        </Card>
      </Box>
    </Box>
  );
};

export default Dashboard;
