import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Heading, Text, FormControl, FormLabel, Input, Button, VStack, useToast } from '@chakra-ui/react';
import { Card, CardHeader, CardBody } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import api from '../api';

const UploadOrders = () => {
    const { register, handleSubmit } = useForm();
    const toast = useToast(); // Initialize useToast
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('file', data.file[0]);

        try {
            const response = await api.post('/insurance/upload_excel', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status !== 200) {
                toast({
                    title: 'Upload Failed',
                    description: response?.data?.detail || 'An error occurred during the upload.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {


                toast({
                    title: 'Upload Successful',
                    description: 'The file has been uploaded successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                navigate('/insurance-orders');
            }

        } catch (error) {

            toast({
                title: 'Upload Failed',
                description: error.response?.data?.detail || 'An error occurred during the upload.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box>
            <Navbar />
            <Box p={5}>
                <Box mt={5}>
                    <Card w="100%" maxW="80%" mx="auto">
                        <CardHeader>
                            <Heading as="h1" fontSize="lg">Upload Insurance</Heading>
                        </CardHeader>
                        <CardBody>
                            <Text>Upload an Excel file with insurance orders.</Text>
                            <Box mt={5}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <VStack spacing={4}>
                                        <FormControl>
                                            <FormLabel>Excel File</FormLabel>
                                            <Input
                                                type="file"
                                                {...register('file', { required: 'File is required' })}
                                            />
                                        </FormControl>
                                        <Button type="submit" colorScheme="teal">Upload</Button>
                                    </VStack>
                                </form>
                            </Box>
                        </CardBody>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
};

export default UploadOrders;
