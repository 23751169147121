import React, { useEffect, useState, useMemo } from 'react';
import { Box, SimpleGrid, Heading, Table, Thead, Tbody, Tr, Th, Td, Flex, IconButton, Text, Tooltip, Select, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Input, Button, VStack, FormControl, FormLabel, Card, CardBody, CardFooter, CardHeader } from '@chakra-ui/react';
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import Navbar from '../components/Navbar';
import api from '../api';
import { useTable, usePagination } from 'react-table';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { logout } from '../auth';
import { useNavigate } from 'react-router-dom';

const formatToLocalISOString = (date) => {
  if (!date) return '';
  const localDate = new Date(date);
  const offset = localDate.getTimezoneOffset() * 60000;
  return new Date(localDate.getTime() - offset).toISOString();
};

const InsuranceOrders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [nameFilter, setNameFilter] = useState('');
  const [noKontrakFilter, setNoKontrakFilter] = useState('');
  const [noKtpFilter, setNoKtpFilter] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);

  const fetchOrders = async (page, size, name, noKontrak, noKtp, start, end) => {
    setLoading(true);
    try {
      const params = {
        skip: page * size,
        limit: size,
        name: name || undefined,
        no_kontrak: noKontrak || undefined,
        no_ktp: noKtp || undefined,
        start_date: start ? formatToLocalISOString(start) : undefined,
        end_date: end ? formatToLocalISOString(end) : undefined,
      };
      const response = await api.get('/insurance', { params });

      setOrders(response.data.orders);
      setPageCount(Math.ceil(response.data.total / size));
    } catch (error) {
      console.error('Error fetching orders', error);
      logout();
      navigate('/login');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders(currentPage, pageSize, nameFilter, noKontrakFilter, noKtpFilter, startDate, endDate);
  }, [currentPage, pageSize, nameFilter, noKontrakFilter, noKtpFilter, startDate, endDate]);

  const columns = useMemo(
    () => [
      {
        Header: "Transaction ID",
        accessor: "trx_id",
      },
      {
        Header: 'Contract No',
        accessor: 'no_kontrak',
      },
      {
        Header: 'Date of Agreement',
        accessor: 'tanggal_akad',
        Cell: ({ value }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: 'Full Name',
        accessor: 'nama_lengkap',
      },
      {
        Header: 'ID Card No',
        accessor: 'no_ktp',
      },
      {
        Header: 'Gender',
        accessor: 'jenis_kelamin',
      },
      {
        Header: 'Date of Birth',
        accessor: 'tanggal_lahir',
        Cell: ({ value }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: 'Place of Birth',
        accessor: 'tempat_lahir',
      },
      {
        Header: 'Phone No',
        accessor: 'no_hp',
      },
      {
        Header: 'Loan Amount',
        accessor: 'nilai_pinjaman',
        Cell: ({ value }) => value.toLocaleString(),
      },
      {
        Header: 'Coverage Amount',
        accessor: 'nilai_uang_pertanggungan',
        Cell: ({ value }) => value.toLocaleString(),
      },
      {
        Header: 'Tenure (months)',
        accessor: 'tenor',
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
    ],
    []
  );

  const data = useMemo(() => orders, [orders]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize: setTablePageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage },
      manualPagination: true,
      pageCount,
    },
    usePagination
  );

  useEffect(() => {
    setTablePageSize(pageSize);
  }, [pageSize, setTablePageSize]);

  const handleFilter = () => {
    setCurrentPage(0);
    fetchOrders(0, pageSize, nameFilter, noKontrakFilter, noKtpFilter, startDate, endDate);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (newPage === pageOptions.length - 1) {
      setCanNextPage(false);
    } else {
      setCanNextPage(true);
    }

    // console.log(newPage);

    if (newPage === 0) {
      setCanPreviousPage(false);
    } else {
      setCanPreviousPage(true);
    }

  };

  return (
    <Box>
      <Navbar />
      <Box p={5} display="grid" gridGap={4} gridAutoFlow="row dense">
        <Card>
          <CardHeader>
            <Heading as="h1" fontSize="lg">Filters</Heading>
          </CardHeader>
          <CardBody>
            <VStack spacing={4} mt={5} mb={5} align="flex-start">
              <SimpleGrid columns={{ base: 1, md: 4 }} spacing={4}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    placeholder="Filter by name"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Contract No</FormLabel>
                  <Input
                    value={noKontrakFilter}
                    onChange={(e) => setNoKontrakFilter(e.target.value)}
                    placeholder="Filter by contract number"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>ID Card No</FormLabel>
                  <Input
                    value={noKtpFilter}
                    onChange={(e) => setNoKtpFilter(e.target.value)}
                    placeholder="Filter by ID card number"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Created At</FormLabel>
                  <Flex direction={{ base: 'column', md: 'row' }} gap={4}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Start Date"
                      dateFormat="yyyy-MM-dd"
                      customInput={<Input />}
                    />
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="End Date"
                      dateFormat="yyyy-MM-dd"
                      customInput={<Input />}
                    />
                  </Flex>
                </FormControl>
              </SimpleGrid>
            </VStack>
          </CardBody>
          <CardFooter>
            <Button onClick={handleFilter} colorScheme="teal">
              Apply Filters
            </Button>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <Heading as="h1" fontSize="lg">Insurance Data</Heading>
          </CardHeader>
          <CardBody>
            <Box overflowX="auto">
              <Table {...getTableProps()} mt={5}>
                <Thead>
                  {headerGroups.map(headerGroup => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {page.map(row => {
                    prepareRow(row);
                    return (
                      <Tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                        ))}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
              m={4}
              alignItems="center"
            >
              <Flex mb={{ base: 4, md: 0 }}>
                <Tooltip label="First Page">
                  <IconButton
                    onClick={() => handlePageChange(0)}
                    isDisabled={!canPreviousPage}
                    icon={<ArrowLeftIcon h={3} w={3} />}
                    mr={4}
                  />
                </Tooltip>
                <Tooltip label="Previous Page">
                  <IconButton
                    onClick={() => handlePageChange(currentPage - 1)}
                    isDisabled={!canPreviousPage}
                    icon={<ChevronLeftIcon h={6} w={6} />}
                  />
                </Tooltip>
              </Flex>

              <Flex alignItems="center">
                <Text flexShrink="0" mr={8}>
                  Page{' '}
                  <Text fontWeight="bold" as="span">
                    {currentPage + 1}
                  </Text>{' '}
                  of{' '}
                  <Text fontWeight="bold" as="span">
                    {pageOptions.length}
                  </Text>
                </Text>
                <Text flexShrink="0">Go to page:</Text>{' '}
                <NumberInput
                  ml={2}
                  mr={8}
                  w={{ base: 'full', md: 28 }}
                  min={1}
                  max={pageOptions.length}
                  value={currentPage + 1}
                  onChange={value => {
                    const page = value ? value - 1 : 0;
                    handlePageChange(page);
                  }}
                  defaultValue={currentPage + 1}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Select
                  w={{ base: 'full', md: 32 }}
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </Select>
              </Flex>

              <Flex mb={{ base: 4, md: 0 }}>
                <Tooltip label="Next Page">
                  <IconButton
                    onClick={() => handlePageChange(currentPage + 1)}
                    isDisabled={!canNextPage}
                    icon={<ChevronRightIcon h={6} w={6} />}
                  />
                </Tooltip>
                <Tooltip label="Last Page">
                  <IconButton
                    onClick={() => handlePageChange(pageCount - 1)}
                    isDisabled={!canNextPage}
                    icon={<ArrowRightIcon h={3} w={3} />}
                    ml={4}
                  />
                </Tooltip>
              </Flex>
            </Flex>
          </CardBody>
          <CardFooter>
          </CardFooter>
        </Card>
      </Box>
    </Box>
  );
};

export default InsuranceOrders;
