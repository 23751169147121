import React from 'react';
import { Box, Flex, Button, Stack } from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { logout } from '../auth';

const Navbar = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <Box bg="black" px={4}>
            <Flex h={16} alignItems="center" justifyContent="space-between">
                <Box color="teal.50"></Box>
                <Flex alignItems="center">
                    <Stack direction="row" spacing={7}>
                        <Button as={RouterLink} to="/dashboard" color="teal.50" variant="link">Dashboard</Button>
                        <Button as={RouterLink} to="/insurance-orders" color="teal.50" variant="link">Insurance Data</Button>
                        <Button as={RouterLink} to="/upload-orders" color="teal.50" variant="link">Upload Insurance</Button>
                        <Button as={RouterLink} to="/insurance-history" color="teal.50" variant="link">Upload History</Button>
                        {/* <Button as={RouterLink} to="/summary" color="teal.50" variant="link">Summary</Button> */}
                        <Button onClick={handleLogout} color="teal.50" variant="outline">Logout</Button>
                    </Stack>
                </Flex>
            </Flex>
        </Box>
    );
};

export default Navbar;
